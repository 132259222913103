import React from 'react';
import { connect } from 'react-redux';
import { Switch, withRouter, Redirect } from 'react-router-dom';
import * as _ from 'lodash';
import PrivateRoute from './utils/PrivateRoute';
import LoadingScreen from './LoadingScreen';
import {DISPLAY_ACTION_TYPES} from '../actions/actionTypes';
import {logoutUser} from "../actions/client";
import SidebarComponent from './SidebarComponent';
import EmployeeComponent from "./EmployeeComponent";
import InvoiceComponent from "./InvoiceComponent";
import ClientProfileComponent from './ClientProfileComponent';
import CongratulationsPage from './CongratulationsPage';
import PlanPurchaseComponent from './PlanPurchaseComponent';
import * as invoiceActions from '../actions/invoice';
import HelpComponent from './HelpComponent';

class DashboardComponent extends React.Component{
    constructor(props){
        super(props);
        const {dispatch} = props;
        props.history.listen(() => {
            dispatch({type:DISPLAY_ACTION_TYPES.HIDE_SIDEBAR});
        });
    }

    componentWillMount(){
        const {dispatch, user} = this.props;
        dispatch(invoiceActions.getClientInvoices(user.credentials.accessToken, user.companyInfo.id));
    }

    renderLoading(){
        return <LoadingScreen/>;
    }

    renderDashboard(user, authenticated) {
         const { invoices } = this.props
        return (
            <div className="dash">
                <SidebarComponent 
                companyName={user.companyInfo.name} 
                companyLogo={user.companyInfo.logoUrl}
                userType={user.companyInfo.type}
                />
                <Switch>
                    {/* <PrivateRoute component={PlanPurchaseComponent} path="/dashboard/plan-purchase" authenticated={authenticated} user={user}/> */}
                    <PrivateRoute component={EmployeeComponent} path="/dashboard/staff" authenticated={authenticated} user={user}/>
                    <PrivateRoute component={InvoiceComponent} path="/dashboard/invoices" authenticated={authenticated} user={user}/>
                    <PrivateRoute component={ClientProfileComponent} path="/dashboard/profile" authenticated={authenticated} user={user}/>
                    <PrivateRoute component={CongratulationsPage} path="/dashboard/congratulations" authenticated={authenticated} user={user}/>
                    <PrivateRoute component={HelpComponent} path="/dashboard/help" authenticated={authenticated} user={user}/>
                    <PrivateRoute component={user.companyInfo.type === 'SME' &&  (invoices.findIndex(invoice => invoice.active_status_id === 1)) === -1 ? PlanPurchaseComponent : EmployeeComponent} path="/dashboard" authenticated={authenticated} user={user}/>
                </Switch>
            </div>
        );
    }


    render(){
        const {user, dispatch, history} = this.props;
         let authenticated = true;

        if( _.isEmpty(user)) return this.renderLoading();
        if(!_.isEmpty(user)) {
            //check if user has selectedClient key in preferences
            //if not, logout user
            //if yes and selectedClient is -1, redirect to accountSelectionPage
            //else render dashboard

            if(user.preferences.selectedClient){
                if(user.preferences.selectedClient === -1){
                    //goto accountSelection
                    return (<Redirect to={{
                                pathname: `/select-account`,
                                state: {
                                    accounts: user.adminInfo.accounts,
                                    accessToken: user.credentials.accessToken
                                }
                            }}/>)
                }else{
                    //render dashboard
                    return this.renderDashboard(user, authenticated);
                }
            }else{
                //logout
                dispatch(logoutUser(history));
                return this.renderLoading();
            }
        }
    }
}
function mapStateToProps(state) {
    const { invoices } = state.invoice;
    return { invoices };
}


export default withRouter(connect(mapStateToProps)(DashboardComponent));